<template>
  <div class="title">
    <h1>{{ title }}</h1>
    <h2>( {{ length }} )</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
};
</script>
